.component-display {
    background-color: #858694;
    color: white;
    text-align: right;
    font-weight: 200;
    flex: 0 0 auto;
    width: 12rem;
  }
  
  .component-display > div {
    font-size: 2.5rem;
    padding: 0.2rem 0.7rem 0.1rem 0.5rem;
  }